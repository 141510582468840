import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import styled from "styled-components";

const ProductRangeWrapper = styled.div`
  margin-top: 2rem;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const SideBarWrapperTitle = styled.h3`
  font-size: 2.8rem;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    text-align: left;
    display: inline-block;
    align-self: flex-start;
  }

  &::after {
    content: "";
    width: 5rem;
    height: 0.4rem;
    background-color: var(--red-dark);
    position: absolute;
    margin: auto;
    bottom: -1rem;
    left: 0px;
    right: 0px;

    @media (min-width: 768px) {
      margin-left: 0px;
    }
  }

  span {
    color: var(--red-dark);
    font-size: 2.2rem;
    font-family: var(--font-quattrocento-regular);
    text-transform: capitalize;
    width: 100%;
    display: block;
    line-height: 1;
    margin: 0px;
  }
`;

const ProductRangeList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  justify-content: center;

  @media (min-width: 768px) {
    flex: 1;
    justify-content: flex-start;
    margin-left: 4rem;
  }

  li {
    margin: 0.6rem;

    @media (min-width: 768px) {
      margin-bottom: 1.2rem;
      margin-top: 0px;
    }
  }

  a {
    background-color: var(--white);
    display: flex;
    border: 2px solid var(--black);
    border-radius: var(--border-large);
    padding: 1rem 1.8rem;
    color: var(--black);
    text-transform: uppercase;
    font-family: var(--font-oswald-bold);
    font-size: 1.6rem;
    transition: border-color 0.25s ease, background-color 0.25s ease,
      color 0.25s ease;

    @media (min-width: 768px) {
      &:hover {
        background-color: var(--black);
        color: var(--yellow-light);
      }
    }
  }
`;

export default function ProductRange() {
  const { ranges } = useStaticQuery(graphql`
    query {
      ranges: allSanityRange(filter: { range_featured: { eq: true } }) {
        nodes {
          id
          range_name
          range_slug {
            current
          }
        }
      }
    }
  `);
  if (ranges.nodes.length === 0) {
    return null;
  }
  return (
    <ProductRangeWrapper>
      <SideBarWrapperTitle>
        <span>selected</span> product range
      </SideBarWrapperTitle>
      <ProductRangeList>
        {ranges.nodes.map((range) => (
          <li key={range.id}>
            <Link to={`/our-products/${range.range_slug.current}`}>
              {range.range_name}
            </Link>
          </li>
        ))}
        <li>
          <Link to='/our-products'>view all</Link>
        </li>
      </ProductRangeList>
    </ProductRangeWrapper>
  );
}
