import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  width: var(--width-percentage);
  margin-left: auto;
  margin-right: auto;
  padding: 1.8rem 2rem;
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    max-width: var(--max-width);
  }
`;

export default function Contentwithoutsb({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
